import React, { useState, useRef, useContext } from "react";
import { useEffect } from "react";
import { Text } from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection";
import FormProduct from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/products/FormProduct";
import ListOfProductsToSetParams from "./ListProductsToSetParams";
import { GlobalContext } from "../../../../../../contexts/GlobalContext";

export default function SetParamProdToPost({
  isLoad,
  setIsLoad,
  paramsToSend,
  setParamsToSend,
  listinType,
  setListinType,
  category
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const globalContext = useContext(GlobalContext);
  const [blockField, setBlockField] = useState(true);

  useEffect(() => {

    if(paramsToSend.length === 0) globalContext.setModalPostProduct(false)

  }, [paramsToSend]);

  const sendToFormData = async (v, currentPosition) => {
    setCurrentIndex(currentPosition);
  };

  const titleFunc = (v) => {
    console.log(paramsToSend)
    if(paramsToSend.length > 0){
      let copyArray = [...paramsToSend];
      copyArray[currentIndex].Title = v;
      setParamsToSend(copyArray);
    }
  };

  const brandFunc = (v) => {
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].BrandName = v;
    setParamsToSend(copyArray);
  };
  const modelFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].sku = v;
    setParamsToSend(copyArray);
  };
  const packagedHeight = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].PackagedHeight = v;
    setParamsToSend(copyArray);
  };
  const packagedLength = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].PackagedLength = v;
    setParamsToSend(copyArray);
  };
  const packagedWidth = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].PackagedWidth = v;
    setParamsToSend(copyArray);
    console.log('currentIndex')
    console.log(copyArray[currentIndex].PackagedWidth)
    console.log(currentIndex)
    console.log('currentIndex')
  };
  const packagedWeightKg = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].PackagedWeightKg = v;
    setParamsToSend(copyArray);
  };
  function formatText(text) {
    text = text.replace(/CARACTER[ÍI]STICAS/g, '\n\nCARACTERÍSTICAS:\n');
    return text.replace(/ - /g, '\n- ');
  }
  const bodyFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].Description = v;
    setParamsToSend(copyArray);
  };
  const bodySeoFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].bodySeo = v;
    setParamsToSend(copyArray);
    console.log(copyArray)
    console.log(paramsToSend)
  };
  

  console.log("validamos el index", currentIndex, paramsToSend);
 
  return (
    <div style={{ width: "100%", height: "auto" }}>
      <ListOfProductsToSetParams sendToFormData={sendToFormData} paramsToSend={paramsToSend} setParamsToSend={setParamsToSend}  />
      {paramsToSend.length > 0 && (
        <FormProduct.ContForms>
          {/* <FormProduct.Contform> */}
            {/* <FormProduct.HeaderContForm>
              <Text size='13pt' fw='500' cl='#FFFFFF'>
                Parametros Generales
              </Text>
            </FormProduct.HeaderContForm> */}
            {/* <FormProduct.BodyContForm>
              <FormProduct.ContInputAndLabel w='340px' mr='5px'>
                <Text size='12pt' fw='500'>
                  Tipo de cuota
                  <sup>*</sup>
                </Text>
                <FormProduct.Select
                  onChange={(e) => {
                    setListinType(e.target.value)
                  }}
                >
                  <option value=''>Seleccionar</option>
                  {cuotasCashea.map((cuota)=>(
                    <option key={cuota} value={cuota}>{cuota} meses</option>
                  ))}
                </FormProduct.Select>
              </FormProduct.ContInputAndLabel>
            </FormProduct.BodyContForm> */}
          {/* </FormProduct.Contform> */}

          <FormProduct.Contform>
            <FormProduct.HeaderContForm>
              <Text size='13pt' fw='500' cl='#FFFFFF'>
                Llene los parametros para el producto a publicar
              </Text>
            </FormProduct.HeaderContForm>
            <FormProduct.BodyContForm>
              <FormProduct.ContInputAndLabel mr='5px'>
                <Text size='12pt' fw='500'>
                  Titulo de la Publicacion
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  placeholder='Titulo de la Publicacion'
                  value={paramsToSend[currentIndex]?.Title}
                  onChange={(e) => titleFunc(e.target.value)}
                  maxLength={60}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Precio
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={true}
                  placeholder='Precio para la publicacion'
                  value={paramsToSend[currentIndex]?.Price}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Marca de Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  placeholder='La marca para el producto'
                  value={paramsToSend[currentIndex]?.BrandName}
                  onChange={(e) => brandFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Referencia interna
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  placeholder='Referencia interna'
                  value={paramsToSend[currentIndex].sku}
                  onChange={(e) => modelFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>
              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Categoria seleccionada
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={true}
                  value={category.name}
                />
              </FormProduct.ContInputAndLabel>
              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Altura empaquetada del Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  type="number"
                  placeholder='Altura empaquetada del Producto'
                  value={paramsToSend[currentIndex]?.PackagedHeight}
                  onChange={(e) => packagedHeight(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>
              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Longitud empaquetada del Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  type="number"
                  placeholder='Longitud empaquetada del Producto'
                  value={paramsToSend[currentIndex]?.PackagedLength}
                  onChange={(e) => packagedLength(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>
              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Ancho empaquetado del Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  type="number"
                  placeholder='Ancho empaquetado del Producto'
                  value={paramsToSend[currentIndex]?.PackagedWidth}
                  onChange={(e) => packagedWidth(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>
              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                    Peso Empaquetado Kg del Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  type="number"
                  placeholder='Peso Empaquetado Kg'
                  value={paramsToSend[currentIndex]?.PackagedWeightKg}
                  onChange={(e) => packagedWeightKg(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>
              <FormProduct.ContInputAndLabel style={{marginTop: '10px', overflow: 'hidden'}} >
                <Text size='12pt' fw='500'>
                  Descripcion Breve del producto para SEO
                </Text>
                <FormProduct.TextArea style={{height: '100px'}}
                  placeholder='Descripcion para el producto'
                  value={paramsToSend[currentIndex]?.bodySeo}
                  onChange={(e) => bodySeoFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel >
              <FormProduct.ContInputAndLabel style={{marginTop: '20px', overflow: 'hidden'}}>
                <Text size='12pt' fw='500'>
                  Descripcion completa del Producto
                </Text>
                <FormProduct.TextArea
                  placeholder='Descripcion para el producto'
                  value={formatText(paramsToSend[currentIndex]?.Description)}
                  onChange={(e) => bodyFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>
            </FormProduct.BodyContForm>
          </FormProduct.Contform>
        </FormProduct.ContForms>
      )}
    </div>
  );
}
